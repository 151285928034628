<template>
  <div>
    <!-- <grid-layout
      :layout.sync="layoutData"
      :col-num="12"
      :row-height="30"
      :is-draggable="false"
      :is-resizable="false"
      :is-mirrored="false"
      :vertical-compact="false"
      :margin="[10, 10]"
      :use-css-transforms="true"
    >
      <grid-item
        v-for="item in layoutData"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.id"
        
      >
        <div id="disItem" @click="onClick($event)">
          
          <ffc
            :layoutType="displayType"
            :itemName="item.key"
            :itemType="item.itemType"
            :itemid="item.i"
            :itemKey="item.i"
            :itemData="item.mainData"
            :itemSetion="item.section"
            :cardid="id"
            :itemDisplayName="item.displayName"
            :itemReportOnly="item.reportOnly"
            @selected="selectedItem"
          ></ffc>
        </div>
      </grid-item>
    </grid-layout> -->
    <!-- :class="{gridContainer: displayType == 'grid', listContainer:displayType == 'list'}" -->
    <!-- <div > 
      <div v-for="(item,id) in layoutData" :key="id" :class="{gridItem: 'grid', active: id === activeItem}"  >
        <div>
           @click="onClick($event, item.i, item.key, item.itemType, item.key)" 
          <ffc
            :layoutType="displayType"
            :itemName="item.key"
            :itemType="item.itemType"
            :itemid="item.i"
            :itemKey="item.key"
            :itemData="item.mainData"
            :itemSetion="item.section"
            :cardid="id"
            :itemDisplayName="item.displayName"
            :itemReportOnly="item.reportOnly"
            @selected="selectedItem"
          ></ffc>
        </div>
      </div>
    </div> -->
    
    <div class="gridContainer">
      <div v-for="(item, id) in layoutData" :key="id" :class="{ gridItem: true, active: id === activeItem }">
        <div>
          <ffc :layoutType="displayType" :itemName="item.key" :itemType="item.itemType" :itemid="item.i"
            :itemKey="item.key" :itemData="item.mainData" :itemSetion="item.section" :cardid="id"
            :itemDisplayName="item.displayName" :itemReportOnly="item.reportOnly" @selected="selectedItem"></ffc>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueGridLayout from "vue-grid-layout";
import OverlayPanel from "primevue/overlaypanel";
import { globalFunction } from "../services/global.function";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    ffc: () => import("../components/folderComponent.vue"),
    signpopup: () => import("../components/signPopup.vue"),
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    OverlayPanel,
  },
  props: {
    alldata: Array,
    noOfColumns: {
      type: Number,
      required: true,
    },
    filterBy: {
      type: String,
      default:'',
    },
    filterMe: {
      type: Boolean,
      default: false
    },
    isFilterEnable: {
      type: Boolean,
      default:false
    },
    cellWith: {
      type: Number,
      required: true,
    },
    cellHeight: {
      type: Number,
      required: true,
    },
    itemFileType: {
      type: String,
    },
    uniqueIdentifer: String,
    sectionType: String,
    layoutType: String,
    displayType: {
      type: String,
      default: "grid",
    },
  },
  data() {
    return {
      delay: 700,
      clicks: 0,
      timer: null,
      txns: [],
      subFloderItem: [],
      currentFolder: "",
      isSignModalActive: false,
      
      activeItem: null,
      selectedtags: [],
      filterVue: [
        {
          name: "Others",
          value: "pendingOnMe",
          color: "slider-yellow",
          defaultname: "Me",
        }
      ],
      searchValue: "",
     
      searchFilter: false,
      
    };
  },
  mounted: function () {
    this.$root.$on("search_bar_data", (data) => {
      this.searchValue = data;
      this.searchFilter = true;
    });
    this.$root.$on("search_bar_empty", () => {
      this.searchValue = "";
      this.searchFilter = false;
    });
  },
  computed: {
    actualWorkFlowID() {
      return this.$store.getters.getWorkFlowID;
    },
    layoutData() {
      let layoutdata = [];
      let dataLenght = this.alldata.length;
      let finalval;
      if (this.sectionType == "pending" || this.sectionType == "archived") {
        let i = 0;
        // console.log("data for all", this.section, ":", this.alldata);
        this.alldata.forEach((item) => {
          layoutdata.push({
            w: this.cellWith,
            h: this.cellHeight,
            i: item.id,
            key: item.name,
            itemType: this.itemFileType ? this.itemFileType : "",
            mainData: item,
            pending_on: item.pending_on,
            section: this.sectionType,
            displayName: item.display_name,
            reportOnly: item.report_only,
          });
        });

        // for (let y = 0; y <= this.noOfColumns; y++) {
        //   for (let x = 0; x <= dataLenght; x = x + 2) {
        //     console.log("data for all",this.alldata[i])
        //     if (this.alldata[i]) {
        //       layoutdata.push({
        //         x: x,
        //         y: y,
        //         w: this.cellWith,
        //         h: this.cellHeight,
        //         i: this.alldata[i].id,
        //         key: this.alldata[i].name,
        //         itemType: this.itemFileType ? this.itemFileType : "",
        //         mainData: this.alldata[i]
        //       });
        //     }
        //     i++;
        //   }
        // }
      } else if (this.sectionType == "available") {
        let i = 0;
        let j = 0;

        for (let y = 0; y <= this.noOfColumns; y++) {
          for (let x = 0; x <= dataLenght; x = x + 2) {
            if (this.alldata[i]) {
              layoutdata.push({
                x: x,
                y: y,
                w: this.cellWith,
                h: this.cellHeight,
                i: this.alldata[i].name + i,
                key: this.alldata[i].name,
                itemType: this.itemFileType ? this.itemFileType : "",
                displayName: this.alldata[i].display_name,
                reportOnly: this.alldata[i].report_only,
              });
              console.log(
                "here",
                this.alldata,
                dataLenght,
                layoutdata,
                this.alldata[i].name
              );
            }
            i++;
          }
          j++;
        }
      }

      if (!this.isFilterEnable && !this.searchFilter) {
        return layoutdata; // Return the original array if no filters are selected
      } else {

        if (this.filterBy == "Elective" || this.filterBy == "Complete") {
          this.filterMe = true;
        }

        console.log("filter me:", JSON.stringify(this.filterMe));
        console.log("filter by: ", JSON.stringify(this.filterBy));

        if (this.filterBy == "Pending" && this.filterMe) {
          layoutdata = layoutdata.filter((item) => {
            return item.mainData.pending_on === "You" && item.mainData.dormant_status !== 'Dormant';
          });
        }

        if (this.filterBy == "Pending" && !this.filterMe) {
          layoutdata = layoutdata.filter((item) => {
            return item.mainData.pending_on !== "You" && item.mainData.pending_on !== "None";
          });
        }

        if (this.filterBy == "Urgent" && this.filterMe) {
          layoutdata = layoutdata.filter((item) => {
            return item.mainData.timeout_status === "Urgent" && item.mainData.pending_on === "You";
          });
        }

        if (this.filterBy == "Urgent" && !this.filterMe) {
          layoutdata = layoutdata.filter((item) => {
            return item.mainData.timeout_status === "Urgent" && item.mainData.pending_on !== "You";
          });
        }

        if (this.filterBy == "Elective") {
          layoutdata = layoutdata.filter((item) => {
            return item.mainData.dormant_status === "Dormant";
          });
        }

        if (this.filterBy == "Complete") {
          layoutdata = layoutdata.filter((item) => {
            return item.mainData.activity_status === "Completed";
          });
        }

        if (this.searchFilter) {
          layoutdata = layoutdata.filter((item) => {
            // console.log("data", item);
            if (this.searchValue.startsWith("wo-")) {
              return (
                item.mainData.id.toLowerCase() == this.searchValue.toLowerCase()
              );
            } else {
              return item.mainData.display_name
                .toLowerCase()
                .includes(this.searchValue.toLowerCase());
            }
          });
        }
      }
      // console.log("Final val", layoutdata);
      return layoutdata;
    },
  },
  methods: {
    onClick: async function (event, itemid, itemName, itemType, itemKey) {
      console.log(itemid);
      this.clicks++;
      if (this.clicks === 1) {
        var self = this;
        this.timer = setTimeout(function () {
          self.clicks = 0;
          // self.$root.$emit('getHistory',{"id":itemid,"name":itemName})
          console.log(self.isSignModalActive);
          if (itemType == "justFile" && !self.isSignModalActive) {
            self.getItemHistroy(itemid);
          }
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        console.log("double click", itemType);
        if (itemType == "fileClosed") {
          this.getSubFolders(itemKey);
          // this.$refs.op.toggle(event);
        } else if (itemType == "justFile") {
          await this.selectItemWorkFlow(itemid);
          this.isSignModalActive = true;
        }
        this.clicks = 0;
      }
    },
    getClass(isFilterOn, value) {
      if (isFilterOn) {
        console.log("filtor color", value)
        if (value == 'pendingOnMe') {
          return 'active-yellow'
        } else if (value == 'timeoutOnMe') {
          return 'active-orange'
        } else if (value == 'completed') {
          return 'active-green'
        } else if (value == 'dorment') {
          return 'active-blue'
        }
      }
    },
    selectedItem: async function (n) {
      this.activeItem = n;
      console.log("Selected Card", n);
    },
    selectItemWorkFlow: async function (value) {
      console.log("workflow value:", value);

      try {
        this.operationIsLoading = true;
        await this.$store.dispatch("SELECT_WORKFLOW", { value });
        this.operationIsLoading = false;
        if (this.actualWorkFlowID === value) {
          globalFunction.notify("is-success", "workflow selected successfully");
          this.txns = this.$store.getters.transactions;
          console.log("Transantion", this.txns);
        } else {
          console.log("workflow selection failed");
          globalFunction.notify(
            "is-danger",
            "could not fetch workflow details, please select another workflow"
          );
        }
      } catch (error) {
        this.operationIsLoading = false;
        console.log("selectWorkflow error:", error);
      }
    },
    getSubFolders(itemKey) {
      console.log(itemKey);
      this.currentFolder = itemKey;
      console.log(itemKey, this.alldata);
      this.alldata.forEach((item) => {
        if (itemKey == item.name) this.subFloderItem = item.workflows;
      });
      console.log("Subfloder", this.subFloderItem);
      this.$store.dispatch("WORKFLOW_SUBFOLDER", {
        data: this.subFloderItem,
      });
    },
    getItemHistroy: async function (value) {
      await this.$store.dispatch("FETCH_HISTORY", { value });
    },
    enableFilter: function () {
      this.isFilterEnable = !this.isFilterEnable;
      if (this.isFilterEnable) {
        this.filterBy = "Urgent";
        this.filterMe = true;
      } else {
        this.filterBy = "";
        this.filterMe = false;
      }
    }
  },
  beforeDestroy() {
    this.$root.$off("item-clicked");
  },
};
</script>
<style lang="scss">
.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 20rem));
  padding: 10px;
  -moz-column-gap: 50px;
  column-gap: 30px;
  margin-top: 20px;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  height: 100vh;
  height: calc(100vh - 132px);
}

.gridItem {
  // background-color: rgba(255, 255, 255, 0.8);
  // border: 1px solid rgba(0, 0, 0, 0.8);
  // padding: 20px;
  // font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
}

.gridItem.active {
  border: 4px solid #76add8;
  border-radius: 7px;
}

.gridItem.active:hover {
  border: 4px solid #76add8;
  border-radius: 7px;
}

// .gridItem:hover {
// background-color: aliceblue;
// }
.listContainer {
  display: contents;
}

.listItem {
  // background-color: rgba(255, 255, 255, 0.8);
  //border: 1px solid rgba(0, 0, 0, 0.8);
  text-align: left;
}

.listItem:hover {
  background-color: aliceblue;
}

.active-yellow {
  background-color: #FCCB1A;
}

.active-orange {
  background-color: #FC7307;
}

.active-green {
  background-color: #698f3f;
}

.active-blue {
  background-color: #76add8;
}

.button .active-yellow {
  background-color: #FCCB1A;
}

.button .active-orange {
  background-color: #FC7307;
}

.button .active-green {
  background-color: #698f3f;
}

.button .active-blue {
  background-color: #76add8;
}</style>
